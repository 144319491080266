import request from '@/utils/request'
export function getDataApi(parentId){
    return request({
        url:'/activity-data/data/scan/'+parentId,
        method:'get'
    })
}

export function totalDataApi(parentId){
    return request({
        url:'/activity-data/data/scan/listIndex/'+parentId,
        method:'get'
    })
}

//获取首页信息
export function getIndexData(){
    return request({
        url:'/organiztionInfo/organiztionInfo/listIndex',
        method:'get'
    })
}
//根据classifyId来获取荣誉图片或者视频
export function getHonorData(id){
    return request({
        url:'/activity-data/SplendidVideoPhoto/classifyId?classifyId='+id,
        method:'get'
    })
} 